import React from 'react';

import landing_art from '../resources/landing_page/landing_page_art.png';
import step_1 from '../resources/landing_page/step_1.png';
import step_2 from '../resources/landing_page/step_2.png';
import step_3 from '../resources/landing_page/step_3.png';
import step_4 from '../resources/landing_page/step_4.png';
import { useNavigate } from 'react-router-dom';

import ProjectOnboardingModal from './ProjectOnboardingModal';

function LandingPage() {

  const navigate = useNavigate(); // Initialize the history object

  return (
    <div className="landing-page">

      <div className="row container mb-5">

        <div className="col-sm-12 col-md-6">
          <span className="landing-tagline row">Remodel your home with ease</span>
 
          <p className="row welcome-title mt-5">Welcome to Placelift</p>
          <p className="row welcome-body">We're here to give you the tools you need to successfully manage your home renovation, no matter where you are.</p>

          <div className="row mt-4">
            <button className="btn btn-primary col-sm-10 col-md-4 col-11 mt-2">Take our questionnaire</button>
            <span className="col-sm-12 col-md-1 col-8"></span>
            <ProjectOnboardingModal />
          </div>

          <br />
          <br />
        </div>

        <div className="col-sm-12 col-md-6 mt-3">
          <img className="landing-art" src={landing_art}></img>
        </div>

      </div>

      <div className="landing-page-middle mt-5">
        <div className="row container">
          <span className="landing-middle-tagline">Streamline your home renovation experience.</span>
          <p className="mt-5">
            Use Placelift to create a project estimate and checklist that matches your renovation vision. 
            Our solution will price your project appropriately for your location and give you insight into the work that needs to be done to achieve your vision.
            Our custom project plans will let you work closely with contractors each step of the way even if you aren't available to be there in person.
          </p>
        </div>

        <div className="row container mt-5">
          <div className="card">
            <img className="card-img-top" src={step_1} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title">Create your project</h5>
              <p className="card-text">Provide us with information about your renovation plans. 
              This will allow us to give you a reasonable project plan for your renovation.</p>
            </div>
          </div>

          <div className="card">
            <img className="card-img-top" src={step_2} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title">Start your project</h5>
              <p className="card-text">Share your project plan with your contractor to ensure you're both on the same page, and avoid unnecessary surprises</p>
            </div>
          </div>

          <div className="card">
            <img className="card-img-top" src={step_3} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title">Track progress</h5>
              <p className="card-text">Stay up to date and communicate with your contractor on the Project Dashboard.  Review their work and process payments with just a few clicks.</p>
            </div>
          </div>

          <div className="card">
            <img className="card-img-top" src={step_4} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title">Enjoy your home</h5>
              <p className="card-text">Experience the joy of a revitalized home that reflects your vision and enhances your daily life.</p>
            </div>
          </div>
        </div>
      </div>


      <footer className="footer">
          <div className="row container">
            <span className="copyright">© 2024 Placelift all rights reserved</span>
          </div>
      </footer>


    </div>
  );
}

export default LandingPage;

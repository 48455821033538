import React from 'react';
import { useNavigate } from 'react-router-dom';

import after_sample_1 from '../resources/samples/after_sample_1.jpg';
import three_dimensional_render_sample from '../resources/samples/3d_render_sample.jpg';

const Materials = ({ selectedPhotoUrl }) => {

  const navigate = useNavigate(); // Initialize the history object

  // Sample data for materials (replace with your actual data)
  const materials = [
    {
      id: 1,
      imageSrc: 'https://certapro.com/fort-collins/wp-content/uploads/sites/1208/2020/11/Shoji-White.png',
      name: 'Paint (main)',
      description: 'SW 7042 - Shoji White SuperPaint Interior Acrylic Latex',
      quantity: '2 gallons',
      price: 139.98,
    },
    {
      id: 2,
      imageSrc: 'https://m.media-amazon.com/images/I/31OlIxAGw0L._AC_.jpg',
      name: 'Paint (accent)',
      description: 'SW 6965 - Frank Blue SuperPaint Interior Acrylic Latex',
      quantity: '1 gallons',
      price: 69.99,
    },
    {
      id: 3,
      imageSrc: 'https://m.media-amazon.com/images/I/71TrsaUT+oL.SS700.jpg',
      name: 'Flooring',
      description: 'Flooret Modin Signature Soho LVP',
      quantity: '800 sqft',
      price: 2400.00,
    },
    {
      id: 4,
      imageSrc: 'https://assets.wfcdn.com/im/84933052/resize-h445%5Ecompr-r85/1234/123418852/Engineered+Wood+0.87%22+Thick+x+5.25%22+Wide+x+96%22+Length+Wall+Base+in+White.jpg',
      name: 'Baseboard',
      description: 'Engineered Wood 0.87" Thick x 5.25" Wide x 96" Length Wall Base in White by Ornamental Mouldings',
      quantity: '104ft',
      price: 913.25,
    },

    
    // Add more material items as needed
  ];

  return (
    <div className="container buffer">
      <div className="row buffer">
        <div className="col-md-4">
          <img src={after_sample_1} alt="Selected Renovation" className="img-fluid" />
        </div>
        <div className="col-md-8">
          {/* Project Summary */}
          <h2>Project Summary</h2>
          <div className="d-flex align-items-center justify-content-center">
            <ul className="project_summary_box">
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>Project estimate:</span>
                  <span>2-3 weeks</span>
                </div>
              </li>
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>Labor:</span>
                  <span>$10,000</span>
                </div>
              </li>
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>Materials:</span>
                  <span>$3,256</span>
                </div>
              </li>
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>Total:</span>
                  <span>$13,256</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row buffer">
        <div className="col-md-4">
          <img src={three_dimensional_render_sample} alt="Selected Renovation" className="img-fluid" />
          Room dimensions: 160 sq ft. 10x16x8
        </div>
        <div className="col-md-8 materials-list">
          <h2>Materials</h2>
          {/* Materials List */}
          <div className="materials-list mt-3">
            {materials.map((material) => (
              <div key={material.id} className="material-item">
                <img src={material.imageSrc} alt="Item" className="material-image" />
                <div className="material-details">
                  <h4 className="material-name">{material.name}</h4>
                  <p className="material-description">{material.description}</p>
                  <div className="material-quantity-price">
                    <p className="material-quantity">Quantity: {material.quantity}</p>
                    <p className="material-price">${material.price.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
      {/* Back button to navigate back to Concept component */}
      <button className="m-2 btn btn-secondary" onClick={() => window.history.back()}>Back</button>
      <button className="m-2 btn btn-primary" onClick={() => navigate("/project")}>Project Outline</button>
    </div>
  );
};

export default Materials;

import React from 'react'; // Add useContext here
import placelift_logo from '../resources/placelift_logo.svg';

function Navbar({ isHomePage }) {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const firstName = localStorage.getItem('first_name')

  return (
    <nav className={`navbar navbar-light navbar-expand-sm ${isHomePage ? 'navbar-animate' : ''}`}>
      <div className="nav-bar-logo">
        <a href="/">
          <img src={placelift_logo} alt="Placelift Logo" />
        </a>
      </div>

      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">

        <ul className="nav-links ms-auto navbar-nav mb-2 mb-lg-0">
          <li><a className="top-level-nav-item nav-item btn" href="/about">About</a></li>
          {isLoggedIn ? (
            <>
              <li><a className="top-level-nav-item nav-item btn" href="/projects">Projects</a></li>
              <li className="nav-item dropdown">
                  <a className="btn btn-secondary dropdown-toggle" href="#" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Welcome, { firstName }
                  </a>

                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" href="/profile">Profile</a>
                    <a className="dropdown-item" href="/settings">Settings</a>
                    <a className="dropdown-item" href="/logout">Logout</a>
                  </ul>
              </li>
            </>
          ) : (
            <>
              <li><a className="top-level-nav-item btn" href="/login">Login</a></li>
              <li><a className="btn btn-secondary" href="/design">Get Started</a></li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;

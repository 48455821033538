import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

//import bathroom_banner from "./resources/project_page/bathroom_project_banner.png";

function Project() {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  const { projectId } = useParams();

  console.log("This is project ID: " + projectId)

  const navigate = useNavigate(); // Initialize the history object
  const [selectedRoom, setSelectedRoom] = useState('bathroom');
  const [selectedRenovationLevel, setSelectedRenovationLevel] = useState(1);
  const [squareFootage, setSquareFootage] = useState(100);
  const [flooringType, setFlooringType] = useState("luxury_vinyl_plank");
  const [addElectricalOutlets, setAddElectricalOutlets] = useState(false);

  const materialsEstimates = {
    bathroom: [
      {'reno_level': 1, 'materials_cost': '$3000'},
      {'reno_level': 2, 'materials_cost': '$8000'},
      {'reno_level': 3, 'materials_cost': '$20000'}
    ],
    bedroom: [
      {'reno_level': 1, 'materials_cost': '$1000'},
      {'reno_level': 2, 'materials_cost': '$5000'},
      {'reno_level': 3, 'materials_cost': '$10000'}
    ],
    kitchen: [
      {'reno_level': 1, 'materials_cost': '$6000'},
      {'reno_level': 2, 'materials_cost': '$12000'},
      {'reno_level': 3, 'materials_cost': '$20000'}
    ],
    'living room': [
      {'reno_level': 1, 'materials_cost': '$1000'},
      {'reno_level': 2, 'materials_cost': '$5000'},
      {'reno_level': 3, 'materials_cost': '$10000'}
    ]
  }

  const [tasks, setTasks] = useState([]);
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(API_ENDPOINT + '/api/project/' + projectId);
        if (response.status === 200) {
          setProjectData(response.data);
          console.log(response.data); 
        } else {
          console.error('Error fetching project details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching project details:', error);
      }

    }

    const fetchTasks = async () => {
      try {
        const response = await axios.get(API_ENDPOINT + '/api/tasks');
        if (response.status === 200) {
          setTasks(response.data);
          //console.log(response.data); // This shows all the tasks correctly
        } else {
          console.error('Error fetching tasks:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
  
    fetchProjectData();
    fetchTasks();
  }, []);
  

  const setOptionalValue = (task_ids, display_value) => {
    setTasks(
      tasks.map((task) =>
        task_ids.includes(task.id) ? { ...task, optional: !display_value } : task
      )
    );
  };



  const filterTasksByRoom = (tasks, selectedRoom, selectedRenovationLevel) => {
    return tasks.filter((task) => task.rooms.includes(selectedRoom) && task.renovation_level <= selectedRenovationLevel && task.optional == false);
  };

  const selectedRoomTasks = filterTasksByRoom(tasks, selectedRoom, selectedRenovationLevel);



  const calculateLaborCostEstimate = (roomTasks) => {
    let laborCost = 0;
  
    for (const task of roomTasks) {

        if (task.sqft_scaling) {
          const cost = parseFloat(task.cost_estimate) * squareFootage
          laborCost += cost;
        } else {
          const cost = parseFloat(task.cost_estimate);
          laborCost += cost;
        }
    }
  
    return laborCost;
  };


  const calculateTimeEstimate = (roomTasks) => {
    let timeEstimate = 0;
  
    for (const task of roomTasks) {
        const time = parseFloat(task.time_estimate);
        timeEstimate += time;
    }
  
    return timeEstimate;
  };

  const calculateMaterialsEstimate = (selectedRoom, selectedRenovationLevel) => {
    // Use zero-based indexing for arrays
    const materialsCost = parseFloat(materialsEstimates[selectedRoom]?.[selectedRenovationLevel - 1]?.materials_cost.slice(1));
  
    // Return the cost or default message
    return materialsCost || 'No estimate found';
  };

  const laborCost = calculateLaborCostEstimate(selectedRoomTasks);
  const timeEstimate = calculateTimeEstimate(selectedRoomTasks);
  const materialsEstimate = calculateMaterialsEstimate(selectedRoom, selectedRenovationLevel)

  const saveContract = async() => {
    try {
      const response = await axios.post(API_ENDPOINT + '/api/save_project');
      //need to provide selectedRoomTasks as post variable
    } catch {
      //do nothing yet
    }
  }

  const shareContract = async() => {
    prompt("Enter the email of the person you'd like to share with")
  }

return (
  <div className="container-fluid">

    <div className="row project-banner"></div>

    <div className="row">
        {/* sidebar code from: https://www.codeply.com/p/ld6vhOqVuG */ }
        <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 sidebar">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 text-white min-vh-100">

                <ul className="mt-4 nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
                        <a href="#" className="nav-link align-middle px-0">
                            <i className="fs-4 bi-house"></i> <span className="ms-1 d-none d-sm-inline">Home</span>
                        </a>
                    </li>
                    <li>
                        <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Inbox</span> </a>

                    </li>
                    <li>
                        <a href="#" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-table"></i> <span className="ms-1 d-none d-sm-inline">Activity</span></a>
                    </li>
                    <li>
                        <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle text-start">
                            <i className="fs-4 bi-bootstrap"></i> <span className="ms-1 d-none d-sm-inline">Project Members</span></a>
                        <ul className="collapse nav flex-column ms-3 text-start" id="submenu2" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">James (owner)</span></a>
                            </li>
                            <li>
                                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Steve (contractor)</span></a>
                            </li>
                            <li>
                                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Haley (family)</span></a>
                            </li>
                            <li>
                                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Short</span></a>
                            </li>
                        </ul>
                    </li>
                    <li>
                    <button className="btn btn-special mt-4">+ Add members</button>
                    </li>
                </ul>
                
                

            </div>
        </div>

      {/* HTML to edit project
      <form className="col-sm-12 col-xl-4">
        <div className="row justify-content-center buffer">
          <div className="col-12">
            What room do you want to renovate?
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="btn-group flex-wrap col-12" role="group">
                <input className="btn-check" type='radio' id='bathroom_selector' name='room_type' value='bathroom' defaultChecked onChange={(e) => setSelectedRoom(e.target.value)} />
                <label className="btn btn-primary" htmlFor='bathroom_selector'>Bathroom</label>

                <input className="btn-check" type='radio' id='bedroom_selector' name='room_type' value='bedroom' onChange={(e) => setSelectedRoom(e.target.value)} />
                <label className="btn btn-primary" htmlFor='bedroom_selector'>Bedroom</label>

                <input className="btn-check" type='radio' id='kitchen_selector' name='room_type' value='kitchen' onChange={(e) => setSelectedRoom(e.target.value)} />
                <label className="btn btn-primary" htmlFor='kitchen_selector'>Kitchen</label>

                <input className="btn-check" type='radio' id='livingroom_selector' name='room_type' value='living room' onChange={(e) => setSelectedRoom(e.target.value)} />
                <label className="btn btn-primary" htmlFor='livingroom_selector'>Living Room</label>
          </div>
        </div>


        <div className="row justify-content-center buffer">
          <div className="col-12">
            How extensive do you want your renovation?
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="btn-group flex-wrap col-12" role="group">

                <input className="btn-check" type='radio' id='facelift_button' name='renovation_type' value='1' defaultChecked onChange={(e) => setSelectedRenovationLevel(e.target.value)} />
                <label className="btn btn-primary" htmlFor='facelift_button'>Facelift</label>

                <input className="btn-check" type='radio' id='rehab_button' name='renovation_type' value='2' onChange={(e) => setSelectedRenovationLevel(e.target.value)}  />
                <label className="btn btn-primary" htmlFor='rehab_button'>Rehab</label>

                <input className="btn-check" type='radio' id='gut_button' name='renovation_type' value='3' onChange={(e) => setSelectedRenovationLevel(e.target.value)} />
                <label className="btn btn-primary" htmlFor='gut_button'>Gut Renovation</label>
          </div>
        </div>

        <div className="row justify-content-center buffer">
          <div className="col-12">
            How many square feet is your room? <br />
            <input className="form-control" type='number' id='sqft' name='sqft' onChange={(e) => setSquareFootage(e.target.value)} />
          </div>
        </div>

        <div className="row justify-content-center buffer">
          <div className="col-12">
            When was your home built? <br />
            <input className="form-control" type='number' id='year_built' name='year_built' />
          </div>
        </div>

        {selectedRenovationLevel >= 2 && (       
          <div className="row justify-content-center buffer">
            <div className="col-12">
              Do you want to add electrical outlets? <br />
              <div className="btn-group flex-wrap col-12" role="group">
                <input className="btn-check" type='radio' id='yes_outlets' name='outlets' onChange={(e) => setOptionalValue([10], true)} />
                <label className="btn btn-secondary" htmlFor="yes_outlets">Yes</label>
                <input className="btn-check" type='radio' id='no_outlets' name='outlets' onChange={(e) => setOptionalValue([10], false)} />
                <label className="btn btn-secondary" htmlFor="no_outlets">No</label>
              </div>
            </div>
          </div>
        )}

        {selectedRoom != "bathroom" && (
          <div className="row justify-content-center buffer">
            <div className="col-12">
              Do you want to add/replace a fan or overhead light? <br />
              <div className="btn-group flex-wrap col-12" role="group">
                <input className="btn-check" type='radio' id='yes_overhead' name='overhead_light' onChange={(e) => setOptionalValue([22, 29], true)}/>
                <label className="btn btn-secondary" htmlFor="yes_overhead">Yes</label>
                <input className="btn-check" type='radio' id='no_overhead' name='overhead_light' defaultChecked onChange={(e) => setOptionalValue([22, 29], false)} />
                <label className="btn btn-secondary" htmlFor="no_overhead">No</label>
              </div>
            </div>
          </div>
        )}

        {selectedRoom != "bathroom" && (
          <div className="row justify-content-center buffer">
            <div className="col-12">
              What type of flooring would you like to install? <br />
              <div className="btn-group flex-wrap col-12" role="group">
                  <input className="btn-check" type='radio' id='lvp_button' name='floor_type' value='luxury_vinyl_plank' />
                  <label className="btn btn-outline-primary" htmlFor='lvp_button'>Luxury Vinyl Plank</label>

                  <input className="btn-check" type='radio' id='engineeredwood_button' name='floor_type' value='engineered_wood'  />
                  <label className="btn btn-outline-primary" htmlFor='engineeredwood_button'>Engineered Wood</label>

                  <input className="btn-check" type='radio' id='hardwood_button' name='floor_type' value='hardwood' />
                  <label className="btn btn-outline-primary" htmlFor='hardwood_button'>Hardwood</label>
              </div>
            </div>
          </div>
        )}

      </form>
      */}

          <div className="col-sm-12 col-xl-8">
            
            <div className="row buffer ps-5">
              <h2 className="text-start" style={{textTransform: 'capitalize'}}>{projectData?.project_name || "Project Details"}</h2>
              
              
              <div className="row mt-4">
                <div className="col-6 text-start">
                  <div className="row">
                    <span className="fw-bold ms-1 mb-1 col-8">Schedule</span>

                    <span className="col-3">
                      <a className="mx-1 " href={projectId}>Past</a>
                      <span className="">|</span> 
                      <a className="mx-1 " href={projectId}>Upcoming</a>
                    </span>
                  </div>

                  <div className="border border-2 mt-1 rounded-3 pre-scrollable">
                  </div>
                  
                </div>


                <div className="col-6 text-start">
                  <span className="fw-bold ms-1 align-left">Updates</span>

                  <div className="border border-2 mt-2 rounded-3 pre-scrollable text-center">
                      <div className="mt-5">No updates at this time.</div>

                      <button className="btn btn-primary mt-4">Request an update</button>
                  </div>

                </div>



              </div>
              
            </div>

            <div className="row">
              Progress
            </div>
              
              {/* 
              
              <div className="align-items-center justify-content-center">
                <div className="row project_summary_box">
                  <li className="list-group-item col-md-3">

                      <span>Project estimate: </span>
                      <span>{timeEstimate} days</span>

                  </li>
                  <li className="list-group-item col-md-3">
                      <span>Labor: </span>
                      <span>${laborCost}</span>
                  </li>
                  <li className="list-group-item col-md-3">
                      <span>Materials:</span>
                      <span>${materialsEstimate}</span>
                  </li>
                  <li className="list-group-item col-md-3">
                      <span>Total:</span>
                      <span>${parseInt(laborCost) + parseInt(materialsEstimate)}</span>
                  </li>
                </div>
              </div>

            </div>
            <div>
              Contractor of record: 
              <button className="m-2 btn btn-outline-info btn-sm" onClick={() => navigate("/contractors")}>Find a Contractor</button>
            </div>
            Permits needed: None
            <ul className="list-group col-md-10 col-sm-12 offset-md-1 buffer">
                <li className="list-group-item text-left">
                  <b className="col-md-1 col-sm-12">Step</b>
                  <b className="col-md-3 col-sm-12">Task</b>
                  <b className="col-md-3 col-sm-12">Cost Estimate</b>
                  <b className="col-md-3 col-sm-12">Time Estimate</b>
                  <b className="col-md-2 col-sm-12">Status</b>
                </li>

              {selectedRoomTasks.map((step, index) => (

                  <li className="list-group-item d-flex justify-content-between" key={index}>
                    <span className="col-md-1 col-sm-12">{index+1}</span>
                    <span className="col-md-3 col-sm-12">{step.task}</span>
                    <span className="col-md-3 col-sm-12">

                    {step.sqft_scaling
                      ? `$${(parseFloat(step.cost_estimate) * squareFootage)}`
                      : step.cost_estimate}
                      </span>

                    <span className="col-md-3 col-sm-12">{step.time_estimate} days</span>
                    <span className="col-md-2 col-sm-12">Not started</span>
                  </li>
                
              ))}
            </ul>
            <button className="m-2 btn btn-success" onClick={() => saveContract()}>Save Contract</button>
            <button className="m-2 btn btn-info" onClick={() => shareContract()}>Share Contract</button>
          */}
          
          </div>

          

          <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0">
            <div className="mt-4 d-flex flex-column px-3 pt-2 min-vh-100">
                <span href="/" className="d-flex align-items-center mb-md-0 me-md-auto text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">Activity</span>
                </span>

                <ul className="flex-column mb-sm-auto mb-0 ms-4 align-items-left text-start align-items-sm-start" id="menu">
                    <li className="">
                        <a href="#" className="nav-link align-middle px-0">
                            <i className="fs-4 bi-house"></i> <span className="ms-1 d-none d-sm-inline">Home</span>
                        </a>
                    </li>
                    <li>
                        <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Activity</span> </a>

                    </li>
                    <li>
                        <a href="#" className="nav-link px-0 align-middle">
                            <i className="fs-4 bi-table"></i> <span className="ms-1 d-none d-sm-inline">Orders</span></a>
                    </li>
                    <li>
                        <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
                            <i className="fs-4 bi-bootstrap"></i> <span className="ms-1 d-none d-sm-inline">Project Members</span></a>
                        <ul className="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            <li className="w-100">
                                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">James (owner)</span></a>
                            </li>
                            <li>
                                <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Steve (contractor)</span></a>
                            </li>
                        </ul>
                    </li>
                </ul>
              
                
            </div>
        </div>


        
      </div>


    </div>
 
  );
}

export default Project;

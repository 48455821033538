import React from 'react';

function About() {
  return (
    <div className="about-page container">
      <div className="header">
        <p className="tagline">Transform your space. With ease.</p>
        <p className="tagline">Live new.</p>
      </div>
      <div className="problem">
        <p className="description">
            Discover the ultimate home remodeling experience with Placelift. Placelift empowers you to effortlessly remodel your rooms and bring your vision to life. Capture a photo, and our advanced AI generates stunning renditions and remodel ideas personalized to your style. From material identification and contractor matching to online contracts, permit tracking, budget planning, and progress monitoring, we provide all the tools you need in one place. Take control of your remodeling journey and transform your home with ease. Join us today and unlock a world of remodeling possibilities at your fingertips.
        </p>

      </div>
    </div>
  );
}

export default About;

import './App.css';
// Create an AuthContext
import React, { useState } from 'react';
import LandingPage from './components/LandingPage';
import About from './components/About';
import Design from './components/Design';
import Project from './components/Project';
import ProjectList from './components/ProjectList';
import Materials from './components/Materials';
import Navbar from './components/Navbar.js';
import LoginRegisterForm from './components/LoginRegisterForm';
import Logout from './components/Logout';



// Provide AuthContext in a parent component
import { AuthContext } from './components/AuthContext';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);

  const handleSuccessfulLogin = (response_data) => {
    setIsLoggedIn(true);
    setToken(response_data.token);

    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn)); // Convert JS object to string
    localStorage.setItem('token', token);

    localStorage.setItem('uid', response_data.uid);

    console.log(localStorage.getItem("uid"))
    localStorage.setItem('first_name', response_data.first_name);
    localStorage.setItem('user_type', response_data.user_type);


    console.log("is logged in: " + isLoggedIn );
    console.log("token = " + token);
  };

  const isHomePage = window.location.pathname === "/";

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, handleSuccessfulLogin }}>
      <div className="App">
        <Navbar isHomePage={isHomePage} />

        <Router>
          <Routes>
              <Route exact path="/about" element={<About />} /> 
              <Route exact path="/design" element={<Design />} /> 
              <Route exact path="/project/:projectId" element={<Project />} /> 
              <Route exact path="/projects" element={<ProjectList />} /> 
              <Route exact path="/login" element={<LoginRegisterForm />} /> 
              <Route exact path="/logout" element={<Logout />} /> 
              <Route exact path="/materials" element={<Materials />} />

              <Route path="/" element={<LandingPage />} /> 
          </Routes>
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
import React, {useState, useEffect} from 'react';
import axios from 'axios';

const ProjectOnboardingModal = () => {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  const uid = localStorage.getItem('uid');


  const [selectedRoom, setSelectedRoom] = useState('bathroom');
  const [selectedBudget, setSelectedBudget] = useState();
  const [selectedRenovationLevel, setSelectedRenovationLevel] = useState(1);
  const [squareFootage, setSquareFootage] = useState(100);
  const [flooringType, setFlooringType] = useState("luxury_vinyl_plank");
  const [addElectricalOutlets, setAddElectricalOutlets] = useState(false);
  const [projectName, setProjectName] = useState(selectedRoom + " project 1");


  const materialsEstimates = {
    bathroom: [
      {'reno_level': 1, 'materials_cost': '$3000'},
      {'reno_level': 2, 'materials_cost': '$8000'},
      {'reno_level': 3, 'materials_cost': '$20000'}
    ],
    bedroom: [
      {'reno_level': 1, 'materials_cost': '$1000'},
      {'reno_level': 2, 'materials_cost': '$5000'},
      {'reno_level': 3, 'materials_cost': '$10000'}
    ],
    kitchen: [
      {'reno_level': 1, 'materials_cost': '$6000'},
      {'reno_level': 2, 'materials_cost': '$12000'},
      {'reno_level': 3, 'materials_cost': '$20000'}
    ],
    'living room': [
      {'reno_level': 1, 'materials_cost': '$1000'},
      {'reno_level': 2, 'materials_cost': '$5000'},
      {'reno_level': 3, 'materials_cost': '$10000'}
    ]
  }

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(API_ENDPOINT + '/api/tasks');
        if (response.status === 200) {
          setTasks(response.data);
          //console.log(response.data); // This shows all the tasks correctly
        } else {
          console.error('Error fetching tasks:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
  
    fetchTasks();
  }, []);
  

  const setOptionalValue = (task_ids, display_value) => {
    setTasks(
      tasks.map((task) =>
        task_ids.includes(task.id) ? { ...task, optional: !display_value } : task
      )
    );
  };



  const filterTasksByRoom = (tasks, selectedRoom, selectedRenovationLevel) => {
    return tasks.filter((task) => task.rooms.includes(selectedRoom) && task.renovation_level <= selectedRenovationLevel && task.optional == false);
  };

  const selectedRoomTasks = filterTasksByRoom(tasks, selectedRoom, selectedRenovationLevel);



  const calculateLaborCostEstimate = (roomTasks) => {
    let laborCost = 0;
  
    for (const task of roomTasks) {

        if (task.sqft_scaling) {
          const cost = parseFloat(task.cost_estimate) * squareFootage
          laborCost += cost;
        } else {
          const cost = parseFloat(task.cost_estimate);
          laborCost += cost;
        }
    }
  
    return laborCost;
  };


  const calculateTimeEstimate = (roomTasks) => {
    let timeEstimate = 0;
  
    for (const task of roomTasks) {
        const time = parseFloat(task.time_estimate);
        timeEstimate += time;
    }
  
    return timeEstimate;
  };

  const calculateMaterialsEstimate = (selectedRoom, selectedRenovationLevel) => {
    // Use zero-based indexing for arrays
    const materialsCost = parseFloat(materialsEstimates[selectedRoom]?.[selectedRenovationLevel - 1]?.materials_cost.slice(1));
  
    // Return the cost or default message
    return materialsCost || 'No estimate found';
  };

  const laborCost = calculateLaborCostEstimate(selectedRoomTasks);
  const timeEstimate = calculateTimeEstimate(selectedRoomTasks);
  const materialsEstimate = calculateMaterialsEstimate(selectedRoom, selectedRenovationLevel)

  const saveProject = async () => {
    try {
      const response = await axios.post(API_ENDPOINT + `/api/add_project`, {
        project_name: projectName,
        owner_id: uid, // You need to set ownerId based on the logged-in user
        budget_preference: selectedBudget,
        room: selectedRoom,
        square_footage: squareFootage,
        renovation_level: selectedRenovationLevel,
        flooring: flooringType,
        cost_estimate: laborCost, // Assuming laborCost is the total labor cost calculated in your component
        materials_cost_estimate: materialsEstimate, // Assuming materialsEstimate is the total materials cost calculated in your component
        labor_cost_estimate: laborCost, // Assuming laborCost is the total labor cost calculated in your component
        time_estimate: timeEstimate // Assuming timeEstimate is the total time estimate calculated in your component
      });
  
      console.log(response.data); // Log the response from the server
      // Optionally, you can handle success or display a message to the user
      window.location = "/projects"
    } catch (error) {
      console.error('Error saving project:', error);
      // Optionally, you can handle errors or display an error message to the user
    }
  };

  return (
    <>
      <div className="modal fade" id="start-project" aria-hidden="true" aria-labelledby="start-project-modal-label" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-header">
              <h5 className="modal-title" id="start-project-modal-label">Where Vision Meets Reality</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p className="modal-body">
              Before we begin we will need some important information from you. If you don't know something &mdash; don't worry, you can always change it later.
            </p>
            <div className="modal-footer">
              <button className="btn btn-primary" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">Let's get started!</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="project-step-1" aria-hidden="true" aria-labelledby="project-step-1-label" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="card-header">
              <span className="progress-group">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <span className="progress progress-active" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">1</span>
                <span className="progress" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">2</span>
                <span className="progress" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">3</span>
                <span className="progress" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">4</span>
                <span className="progress" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">5</span>
              </span>
            </div>
            <div className="modal-header">
              <h5 className="modal-title" id="project-step-1-label">Which room are you looking to renovate?</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="btn-group flex-wrap col-12" role="group">
                    <input className="btn-check" type='radio' id='bathroom_selector' name='room_type' value='bathroom' defaultChecked onChange={(e) => setSelectedRoom(e.target.value)} />
                    <label className="btn btn-primary" htmlFor='bathroom_selector'>Bathroom</label>

                    <input className="btn-check" type='radio' id='bedroom_selector' name='room_type' value='bedroom' onChange={(e) => setSelectedRoom(e.target.value)} />
                    <label className="btn btn-primary" htmlFor='bedroom_selector'>Bedroom</label>

                    <input className="btn-check" type='radio' id='kitchen_selector' name='room_type' value='kitchen' onChange={(e) => setSelectedRoom(e.target.value)} />
                    <label className="btn btn-primary" htmlFor='kitchen_selector'>Kitchen</label>

                    <input className="btn-check" type='radio' id='livingroom_selector' name='room_type' value='living room' onChange={(e) => setSelectedRoom(e.target.value)} />
                    <label className="btn btn-primary" htmlFor='livingroom_selector'>Living Room</label>
              </div>

              <div className="row justify-content-center buffer">
                <div className="col-7">
                  How many square feet is your room? <br />
                  <input className="form-control" type='number' id='sqft' name='sqft' onChange={(e) => setSquareFootage(e.target.value)} />
                </div>
              </div>

              <div className="row justify-content-center buffer">
                <div className="col-7">
                  When was your home built? <br />
                  <input className="form-control" type='number' id='year_built' name='year_built' />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-bs-target="#start-project" data-bs-toggle="modal" data-bs-dismiss="modal">Go back</button>
              <button className="btn btn-primary" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">Continue</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="project-step-2" aria-hidden="true" aria-labelledby="project-step-2-label" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="card-header">
              <span className="progress-group">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <span className="progress" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">1</span>
                <span className="progress progress-active" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">2</span>
                <span className="progress" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">3</span>
                <span className="progress" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">4</span>
                <span className="progress" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">5</span>
              </span>            </div>
            <div className="modal-header">
              <h5 className="modal-title" id="project-step-2-label">How extensive will your renovation be?</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="btn-group flex-wrap col-12" role="group">
                <input className="btn-check" type='radio' id='facelift_button' name='renovation_type' value='1' defaultChecked onChange={(e) => setSelectedRenovationLevel(e.target.value)} />
                <label className="btn btn-primary" htmlFor='facelift_button'>Facelift</label>

                <input className="btn-check" type='radio' id='rehab_button' name='renovation_type' value='2' onChange={(e) => setSelectedRenovationLevel(e.target.value)}  />
                <label className="btn btn-primary" htmlFor='rehab_button'>Rehab</label>

                <input className="btn-check" type='radio' id='gut_button' name='renovation_type' value='3' onChange={(e) => setSelectedRenovationLevel(e.target.value)} />
                <label className="btn btn-primary" htmlFor='gut_button'>Gut Renovation</label>
              </div>            
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">Go back</button>
              <button className="btn btn-primary" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">Continue</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="project-step-3" aria-hidden="true" aria-labelledby="project-step-3-label" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="card-header">
              <span className="progress-group">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <span className="progress" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">1</span>
                <span className="progress" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">2</span>
                <span className="progress progress-active" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">3</span>
                <span className="progress" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">4</span>
                <span className="progress" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">5</span>
              </span>            </div>
            <div className="modal-header">
              <h5 className="modal-title" id="project-step-3-label">How spendy are you feeling?</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Would you prefer to keep your materials and labor costs on a tight budget, or do you want only the highest quality?</p>
              <div className="btn-group col-12" role="group">
                <input className="btn-check" type='radio' id='budget1' name='budget' value='$' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-primary" htmlFor='budget1'>$</label>

                <input className="btn-check" type='radio' id='budget2' name='budget' value='$$' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-primary" htmlFor='budget2'>$$</label>

                <input className="btn-check" type='radio' id='budget3' name='budget' value='$$$' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-primary" htmlFor='budget3'>$$$</label>

                <input className="btn-check" type='radio' id='budget4' name='budget' value='$$$$' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-primary" htmlFor='budget4'>$$$$</label>
              </div>  
                <input type='radio' id='no-budget' name='budget' value='none' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label htmlFor="no-budget">Not sure</label>   
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">Go back</button>
              {selectedRenovationLevel >= 2 || selectedRoom !="bathroom" ?
              <button className="btn btn-primary" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">Continue</button>
              :
              <button className="btn btn-primary" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">Continue</button>
              }
              </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="project-step-4" aria-hidden="true" aria-labelledby="project-step-4-label" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="card-header">
            <span className="progress-group">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <span className="progress" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">1</span>
                <span className="progress" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">2</span>
                <span className="progress" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">3</span>
                <span className="progress progress-active" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">4</span>
                <span className="progress" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">5</span>
              </span>            </div>
            <div className="modal-header">
              <h5 className="modal-title" id="project-step-4-label">Other information</h5>
              
            </div>
            <div className="modal-body">

              {selectedRenovationLevel >= 2 && (  
                <div className="col-12">
                  Do you want to add electrical outlets? <br />
                  <div className="btn-group flex-wrap col-12" role="group">
                    <input className="btn-check" type='radio' id='yes_outlets' name='outlets' onChange={(e) => setOptionalValue([10], true)} />
                    <label className="btn btn-primary" htmlFor="yes_outlets">Yes</label>
                    <input className="btn-check" type='radio' id='no_outlets' name='outlets' onChange={(e) => setOptionalValue([10], false)} />
                    <label className="btn btn-primary" htmlFor="no_outlets">No</label>
                  </div>
                </div> 
              )}

              {selectedRoom != "bathroom" && (
                  <div className="col-12">
                    Do you want to add/replace a fan or overhead light? <br />
                    <div className="btn-group flex-wrap col-12" role="group">
                      <input className="btn-check" type='radio' id='yes_overhead' name='overhead_light' onChange={(e) => setOptionalValue([22, 29], true)}/>
                      <label className="btn btn-primary" htmlFor="yes_overhead">Yes</label>
                      <input className="btn-check" type='radio' id='no_overhead' name='overhead_light' defaultChecked onChange={(e) => setOptionalValue([22, 29], false)} />
                      <label className="btn btn-primary" htmlFor="no_overhead">No</label>
                    </div>
                  </div>
              )}

              {selectedRoom != "bathroom" && (
                  <div className="col-12">
                    What type of flooring would you like to install? <br />
                    <div className="btn-group flex-wrap col-12" role="group">
                        <input className="btn-check" type='radio' id='lvp_button' name='floor_type' value='luxury_vinyl_plank' />
                        <label className="btn btn-outline-primary" htmlFor='lvp_button'>Luxury Vinyl Plank</label>

                        <input className="btn-check" type='radio' id='engineeredwood_button' name='floor_type' value='engineered_wood'  />
                        <label className="btn btn-primary" htmlFor='engineeredwood_button'>Engineered Wood</label>

                        <input className="btn-check" type='radio' id='hardwood_button' name='floor_type' value='hardwood' />
                        <label className="btn btn-primary" htmlFor='hardwood_button'>Hardwood</label>
                    </div>
                </div>
              )}

            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">Go back</button>
              <button className="btn btn-primary" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">Continue</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="project-step-5" aria-hidden="true" aria-labelledby="project-step-5-label" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="card-header">
              <span className="progress-group">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <span className="progress" data-bs-target="#project-step-1" data-bs-toggle="modal" data-bs-dismiss="modal">1</span>
                <span className="progress" data-bs-target="#project-step-2" data-bs-toggle="modal" data-bs-dismiss="modal">2</span>
                <span className="progress" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">3</span>
                <span className="progress" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">4</span>
                <span className="progress progress-active" data-bs-target="#project-step-5" data-bs-toggle="modal" data-bs-dismiss="modal">5</span>
              </span>            </div>
            <div className="modal-header">
              <h5 className="modal-title" id="project-step-5-label">Finally, pick a name for your project!</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <div className="row justify-content-center buffer">
                <div className="col-7">
                  Project name <br />
                  <input className="form-control" type='text' id='project_name' name='project_name' defaultValue={projectName} onChange={(e) => setProjectName(e.target.value)} />
                </div>
              </div>

            </div>
            <div className="modal-footer">
              {selectedRenovationLevel >= 2 || selectedRoom !="bathroom" ?
                <button className="btn btn-primary" data-bs-target="#project-step-4" data-bs-toggle="modal" data-bs-dismiss="modal">Go back</button>
                :
                <button className="btn btn-primary" data-bs-target="#project-step-3" data-bs-toggle="modal" data-bs-dismiss="modal">Go back</button>
              }
              <button className="btn btn-primary" onClick={saveProject}>Submit</button>
            </div>
          </div>
        </div>
      </div>



      <button className="btn btn-outline-primary col-sm-12 col-md-4 col-11 mt-2" data-bs-toggle="modal" href="#start-project" role="button">Start a renovation</button>

    </>
  );
};

export default ProjectOnboardingModal;

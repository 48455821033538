import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import after_sample_1 from '../resources/samples/after_sample_1.jpg';
import after_sample_2 from '../resources/samples/after_sample_2.jpg';
import after_sample_3 from '../resources/samples/after_sample_3.jpg';

const Design = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [selectedBudget, setSelectedBudget] = useState();
  const [selectedRenovation, setSelectedRenovation] = useState();
  const [submissionStatus, setSubmissionStatus] = useState('idle'); // 'idle', 'loading', 'completed'
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSelection, setImageSelection] = useState(null);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
  const navigate = useNavigate(); // Initialize the history object

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleRenovationChange = (event) => {
    setSelectedRenovation(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmissionStatus('loading');
    
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('budget', selectedBudget);
    formData.append('renovation', selectedRenovation);

    try {
      const res = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setSelectedImage(res.data.image);
      setSubmissionStatus('completed');
    } catch (err) {
      console.error(err);
      //setSubmissionStatus('error');
      //faking it
      setSubmissionStatus('error');
    }
  };

  const handleTryAgain = () => {
    setSubmissionStatus('idle');
    setSelectedImage(null);
    setImageSelection(null);
  };

  const handleSelectImage = (index) => {
    setImageSelection(index);
  };
  
  const handleChooseStyle = () => {
    setSelectedPhotoUrl(selectedImage);
    navigate('/materials', { state: { selectedImage } });
    // Implement the logic for when the user chooses a style
    // For example, you can display a message or perform some action.
    // You can also reset the form or navigate to another page.
  };
  
  const handleFindOtherStyles = () => {
    // Implement the logic for when the user wants to find other styles
    // For example, you can reset the form or provide new image options.
  };

  return (
    <div className="container buffer">
      {submissionStatus === 'idle' && (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <input className="btn btn-secondary" type="file" onChange={handleFileChange} />
          </div>
        </div>
        <div className="row justify-content-center buffer">
          <div className="btn-group col-4" role="group">

                <input className="btn-check" type='radio' id='budget1' name='budget' value='low' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-outline-primary" htmlFor='budget1'>$</label>

                <input className="btn-check" type='radio' id='budget2' name='budget' value='medium' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-outline-primary" htmlFor='budget2'>$$</label>

                <input className="btn-check" type='radio' id='budget3' name='budget' value='high' onChange={(e) => setSelectedBudget(e.target.value)} />
                <label className="btn btn-outline-primary" htmlFor='budget3'>$$$</label>
          </div>
        </div>

        <div className="row justify-content-center buffer">
          <div className="col-4">
            How extensive do you want your renovation?
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="btn-group col-4" role="group">

                <input className="btn-check" type='radio' id='facelift_button' name='renovation_type' value='Facelift' onChange={(e) => setSelectedRenovation(e.target.value)} />
                <label className="btn btn-outline-primary" htmlFor='facelift_button'>Facelift</label>

                <input className="btn-check" type='radio' id='rehab_button' name='renovation_type' value='Rehab' onChange={(e) => setSelectedRenovation(e.target.value)} />
                <label className="btn btn-outline-primary" htmlFor='rehab_button'>Rehab</label>

                <input className="btn-check" type='radio' id='gut_button' name='renovation_type' value='Gut' onChange={(e) => setSelectedRenovation(e.target.value)} />
                <label className="btn btn-outline-primary" htmlFor='gut_button'>Gut Renovation</label>

          </div>
        </div>

        <div className="row buffer">
          <div className="col">
            <button className="btn btn-primary" type='submit'>Submit</button>
          </div>
        </div>
      </form>
   )}

   {submissionStatus === 'loading' && (
     <div className="text-center">
       <p>Loading...</p>
     </div>
   )}

   {submissionStatus === 'completed' && selectedImage && (
     <div className="text-center buffer">
       <p>Choose from the following images:</p>
       <img src={selectedImage} alt="Renovation Choices" />
       <button onClick={handleTryAgain}>Try Again</button>
     </div>
   )}

   {submissionStatus === 'error' && (


      <div className="text-center buffer">
        <p>Choose from the following images:</p>
          <div className="d-flex justify-content-between">
            {[
              { src: after_sample_1, alt: 'Sample 1' },
              { src: after_sample_2, alt: 'Sample 2' },
              { src: after_sample_3, alt: 'Sample 3' },
            ].map((image, index) => (
              <div key={index} className={`col-4 image-container m-2 ${index === imageSelection ? 'selected' : ''}`} onClick={() => handleSelectImage(index)}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className={`img-fluid ${imageSelection !== null && index !== imageSelection ? 'unselected-image' : ''}`}
                />
              </div>
            ))}
          </div>
        <div>
          <button className="m-2 btn btn-success" onClick={handleChooseStyle}>Choose this style</button>
          <button className="m-2 btn btn-warning" onClick={handleFindOtherStyles}>Find other styles like this</button>
          <button className="m-2 btn btn-danger" onClick={handleTryAgain}>Try Again</button>
        </div>
      </div>
   )}

 </div>
);
};

export default Design;
import React, {useState, useEffect} from 'react';
import axios from 'axios';

const ProjectList = () => {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  const uid = localStorage.getItem('uid');

  const [projects, setProjects] = useState([]);


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(API_ENDPOINT + `/api/projects?uid=${uid}`);
        if (response.status === 200) {
          setProjects(response.data);
          console.log(response.data); // This shows all the projects correctly
        } else {
          console.error('Error fetching projects:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
  
    fetchProjects();
  }, []);
  

  return (
    <div className="container">
      <h2 className="my-4">Project List</h2>
      <ul className="list-group">
        {projects.map(project => (
          <li key={project.id} className="list-group-item">
            <div>
              <h3 className="mb-2">
                <a href={`/project/${project.id}`} className="text-decoration-none">{project.project_name}</a> {/* Use href for navigation */}
              </h3>
              <p className="mb-1">{project.room} - {parseInt(project.square_footage)} sq ft.</p>
              <p className="mb-1">Renovation level: {project.renovation_level} - Budget Preference: {project.budget_preference}</p>
              <div className="mb-1">Cost estimate: ${parseInt(project.cost_estimate)}
                <ul>
                  <li>Materials cost estimate: ${parseInt(project.materials_cost_estimate)}</li>
                  <li>Labor cost estimate: ${parseInt(project.labor_cost_estimate)}</li>
                </ul>
              </div>

              <div className="mb-1">Time estimate: {parseInt(project.time_estimate)} days</div>

              {/* Add more project details as needed */}
            </div>
          </li>
          
        ))}
      </ul>
    </div>
  );
};

export default ProjectList;
